const englishTranslations = {
    english: 'English',
    spanish: 'Spanish',
    language: 'Language',
    calendar: 'Calendar',
    contact_us: 'Contact Us',
    about_us: 'About Us',
    getting_started: 'Getting Started',
    our_ministers: 'Our Ministers',
    new_here: 'New Here',
    teaching_archive: 'Teaching Archives',
    upcoming_event: 'Upcoming Event',
    study_outlines: 'Study Outlines',
    resources: 'Resources',
    home: 'Home',
    next: 'Next',
    all_events: 'All Events',
    more_coming_events: 'More Coming Events',
    featured_sermon_title: 'Theocratic Government & Order',
    featured_sermon_description:
        "Government and order is a key. This key unlocks your lawful, legal right to petition the King. Without governments in place, you don't have a church/ lawful assembly /eklessia. You don't have a legal right to petition the king for anything. Yes he can be merciful and respond from time to time, as a merciful high priest, but it's a much different experience if you form a lawful assembly and get the benefits of a citizen. Many assemblies are petitioning the High Priest but not the king because they don't have a lawful assembly. Rulership is separate from the doma gifts. The requirements of an elder and rulership are about character, gifts are gifts. ",
    minister: 'Minister',
    apostle: 'Apostle',
    teacher: 'Teacher',
    prophet: 'Prophet',
    evangelist: 'Evangelist',
    pastor: 'Pastor',
    daniel_11_img:
        'https://d1biklpwskup2.cloudfront.net/Beach_Daniel_11_Cover.png',
    joels_army: 'https://d1biklpwskup2.cloudfront.net/Joel_Army.png',
    view_ministers: 'View Our Ministers',
    new_members: 'New Members',
    view_teachings: 'View Teachings',
    featured_sermon: 'Featured Sermon',
    watch_online: 'Watch Online',
    watch_youtube: 'Watch On YouTube',
    how_to_study: 'How do I study?',
    foundational_teachings: 'Foundational Teachings',
    additional_study: 'Additional Study',
    study_directions: `Learning the ways of God is like school. Therefore we are encouraged to study to show ourselves approved. We recommend you listen to (or study) the teachings five times. 
        The first time, listen and follow along with your bible. The second time, listen, follow along with your bible, and write down the scriptures used. 
        The third time, listen and write down the commentary on the scriptures used. The fourth time, listen and write down any commentary you missed and questions you have. 
        The fifth time, go through all of the scriptures along with your concordance and seek out different points that were not clear to you.`,
    our_beliefs: 'Our Beliefs',
    beliefs1:
        'We believe that Jesus is the Messiah and the Son of Almighty God the Father. He came as the Lamb of God to be the perfect sacrifice for all of mankind’s sin. He died and three days later he was raised from the dead and now sits at the right hand of the Father. We believe in the Trinity, the Father, the Son, and the Holy Ghost.',
    beliefs2:
        "We believe in keeping God's commandments (Torah), which includes the appointed festivals (i.e. Sabbath, Rosh Chodesh, Pesach, Sukkot, and Yom Teruah). We DO NOT believe that the Law (Torah) was abolished when Jesus came to this earth. We believe Jesus Christ was an extension of the covenant God gave through the Torah.",
    beliefs3:
        'We believe in the baptism of the Holy Ghost with the evidence of speaking in other tongues separate from salvation. We believe in healing, deliverance, miracles, signs and wonders.',
    beliefs4:
        'We believe in the use of the five fold ministry and the function of each of these five offices within the church. We believe in the working of the spiritual gifts identified in 1 Corinthians the 12th chapter.',
    mission:
        'We believe that the days that lie ahead for the the world are going to become very difficult times. The church is slipping deeper and deeper into a coma from which only a remnant will awaken. It is this remnant that will do the great exploits in these last days. Our goal is to help the remnant identify themselves and prepare for the return of our Lord and Savior Jesus Christ.',
    curriculum_header_1: 'Temptation, Good Fruit, and the Mind War',
    curriculum_header_2: "God's Covenant and Festivals",
    curriculum_header_3: 'Prayer & Spiritual Warfare',
    curriculum_header_4: 'Additional Festivals',
    purpose_of_temptation: 'Purpose of Temptation',
    fruit_of_spirit: 'Fruit of the Spirit',
    war_for_mind: 'War for Your Mind',
    keeping_the_sabbath: 'Keeping the Sabbath',
    everlasting_covenant: 'Everlasting Covenant',
    transition_of_church: 'Transition of the Church',
    familiar_spirits: 'Familiar Spirits',
    remnant_102: 'Remnant 102',
    divine_guidance: 'Divine Guidance',
    prophets_and_prophecy: 'Prophets and Prophecy',
    passover: 'The Festival of Passover',
    feast_of_weeks: 'The Feast of Weeks',
    sukkot: 'The Feast of Sukkot',
    read_more: 'Read More...',
    coming_soon: 'Coming Soon...',
    temptation_desc:
        'Why does God allow us to be tempted? It is part of the Law of Increase. In order to gain, you must be tested.',
    fruit_desc:
        'We are expected to produce fruit. Without fruit, your walk with Christ does not exist. Learn the attributes of each fruit',
    war_mind_desc:
        'Your mind is the biggest enemy you have. It is often the reason for a lack of spiritual growth. Learn to overcome your mind.',
    sabbath_desc:
        'Sabbath was given to mankind as a sign that you are one of His children. Learn about the blessings of observing this holy day.',
    covenant_desc:
        "God created a covenant. Jesus was was an extension of the covenant, not the end. Learn what hinders many Christian's blessings.",
    transition_desc:
        'God brings revelation knowledge to each generation to bring the church closer to His image. The church is once again in transition.',
    familiar_desc:
        'There are spirits of darkness that are familiar with you. They have potentially been in your family for generations. Learn how to overcome them.',
    guidance_desc:
        'Each of us need to be guided by God. But there are rules to follow to avoid key mistakes. Learn the rules of how God guides us.',
    prophets_desc:
        'How do you identify real prophets? What are the consequences of listening to false prophets? Learn the rules here.',
    passover_desc:
        "Passover is a seven day festival where we celebrate how a Mighty God brought our ancestors out of Egypt. Learn about it's history and importance for today.",
    weeks_desc:
        "Pentecost is a one day festival where that commemorates the giving of Torah as well as the giving of the Holy Spirit. Learn about it's history and importance for today.",
    sukkot_desc:
        "Sukkot is an eight day festival where we remember that God caused our ancestors to dwell in tents when He brought them out of Egypt. Learn about it's history and importance for today.",
    larry_bio: `Originally from Dallas, TX, Larry grew up as a pastor's kid in a
        Pentecostal/Holiness church. Unfortunately, this upbringing along with his adult Christian experience caused him to observe the good, the bad, and the ugly of church
        life. The behind the scenes look at doctrinal disagreements, financial disputes, and internal power struggles caused a deep disatisfaction within him for church/religion. 
        This almost caused him to forsake God altogether. In 2008, He attended a service where he witnessed a prophet lay hands on people and miracles manifest. 
        Short legs grew, diseases were healed, and demons were cast out. He had great excitement because now he knew: "God is not dead". 
        God used this to birth a deep hunger for personal intimacy with Him through the study of His word, fasting, and prayer. 
        Larry then spent the next 12 years serving and training under prophetic ministry. In October 2019, three prophets ordained and commissioned him as an Apostle and Prophet to the body of Christ
        in the sight of many witnesses according to Acts 13:2 and 1 Timothy 4:14-16. 
        His focus is now on teaching deeper intimacy with God. He is interested in creating mature disciples of Christ, not believers in a particular doctrine.`,
    jawana_bio: `Raised in Minnesota, Jawana grew up heavily involved in a Non-Denominational church. 
    After serving and learning under ministry for almost 12 years, she was ordained and commissioned as a Teacher to the body of Christ
    in the sight of many witnesses according to Acts 13:2 and 1 Timothy 4:14-16. Her passions include ministering to children and encouraging believers to strive towards maturity in Christ.`,
    jon_bio: `Originally from Chelsea, London, Brother
        Stevens currently functions as an evangelist
        to the body of Christ.`,
    trevor_bio: `Originally from California, Brother Cole
        currently functions as a pastor to the body
        of Christ.`,
    roger_bio: `Originally from Caracas, Venezuela, Brother
        Gavidia currently functions as a teacher to
        the body of Christ.`,
    meet_our_team: 'Meet Our Team',
    shabbat_service: 'Shabbat Service',
    email_success:
        'Thank you for your message! We will respond as soon as we can.',
    the_trinity: 'The Trinity',
    the_law: 'The Law (Torah)',
    holy_spirit: 'The Holy Spirit',
    offices_and_gifts: 'Gifts and Callings',
    rosh_hashanah: 'Rosh Hashanah',
    rosh_hashanah_desc:
        'Rosh Hashanah "The New Year" in Hebrew. A Feast we are Commanded to keep. These deeply Spiritual Holy Days Commemorates the Creation of the World. A Time to Repent. A Time to Reflect on Choices You\'ve made.',
    sheep_goat_wolves: 'Sheep, Goats, and Wolves',
    sheep_goat_wolves_desc:
        "We are warned to beware because false representatives of Christ will come amongst us with sheep's clothing. You are either a sheep, goat, or wolf as it relates to the flock of God.",
    jonathan_bio:
        "Born and raised in the Washington DC area, Jonathan grew up in a Non-Denominational church. After years of attending, volunteering, and being mentored within that church, he didn't see the same God he was taught about being exemplified by its members or leaders. In 2007, this crisis-of-faith caused him to leave the church and search for meaning by enlisting in the United States Marine Corps; he served 4 years and deployed on two tours to Afghanistan as an infantryman. After the Marines, he was still searching for meaning and direction that he couldn't find within the church or the Marines. In January of 2012 he attended a weekend conference with a prophet who had a deliverance ministry, and saw God's power operate. He has sought God ever since. He was ordained into ministry as a teacher in April 2020.",
    fred_bio:
        'Born in Takoma Park, Maryland, Fred grew up in a Seventh-Day Adventist church. He was ordained into ministry as a pastor in April 2020.',
    paul_bio:
        'Born and raised in France, Paul escaped a family history of witchcraft and gave his life to Christ in the early 1980s. He was personally discipled by an apostle and prophet for several years. In 2010, he was ordained and commissioned as an Apostle and Prophet to the body of Christ in the sight of many witnesses according to Acts 13:2 and 1 Timothy 4:14-16. He specializes in bringing revelation knowledge and prophetic insight about the end times. ',
    daren_bio:
        'Born and raised around the Massachusets area, Daren was ordained into ministry as an evangelist in October 2019.',
    strange_fire:
        'Offering strange fire is serving God presumptuously but refusing to take orders, refusing to accept His delegation of authority. Authority is God-given, and He stands behind His authority with the full weight of His power.',
    voice_from_inside:
        'Hearing the voice of God is a skill to be learned. However, most believers are taught to use the strength of their minds to hear God. But God only speaks to your spirit. Therefore, you hear God from the inside-out, not the outside-in.',
    conviction_condemnation:
        'Conviction will show you the rule book, and you realize you are guilty of an offense. Condemnation accuses you of breaking the rule book and brings the guilt of needing punishment. Condemnation exaggerates the expected penalty/punishment.',
    making_decisions:
        'Being led by the Spirit of God is the mark of being a son of God (Romans 8:14). How do you make decisions? How do you get yourself in a position to be led by the Spirit of God? Listen as we address these questions.',
    theocratic_government:
        "Government and order is a key. This key unlocks your lawful, legal right to petition the King. Without governments in place, you don't have a church/ lawful assembly /eklessia. Many assemblies are petitioning the High Priest but not the King because they don't have a lawful assembly.",
}

export default englishTranslations
