import React, { Component } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Row,
} from 'reactstrap'
import './WatchOnlinePage.scss'
import { BasePage } from '../../components/base-page/BasePage'
import { trackEvent, trackPageView } from '../../services/GoogleApiService'
import { connect } from 'react-redux'
import { Strings } from '../../localizations'

export class WatchOnlinePage extends Component {
    componentDidMount() {
        trackPageView(window.location)
    }

    render() {
        return (
            <BasePage
                breadcrumbTitle={'Videos'}
                pageTitle={'Watch Online'}
                cssClasses={'watch-online'}
            >
                <Row className={'mb-3'}>
                    <Col xs="12" sm="6" md="4" className="py-3">
                        <Card
                            body
                            onClick={() => {
                                window.open(
                                    `https://www.youtube.com/playlist?list=PLrz-Es7TsDMMa0S81otvDumjO3YD_wAJL`
                                )
                            }}
                        >
                            <img
                                className={'sermon-thumbnail'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Strange_Fire.png"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Offering Strange Fire
                                </CardTitle>

                                <CardText>{Strings.strange_fire}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent('Strange Fire video')
                                    }}
                                >
                                    {Strings.watch_online}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="py-3">
                        <Card
                            body
                            onClick={() => {
                                window.open(
                                    `https://www.youtube.com/playlist?list=PLrz-Es7TsDMO-juOP0WrRucyKSxCOEUt-`
                                )
                            }}
                        >
                            <img
                                className={'sermon-thumbnail'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Hear_Voice_Inside.png"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    How to Hear God's Voice from the Inside
                                </CardTitle>

                                <CardText>{Strings.voice_from_inside}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'How to Hear Gods Voice from the Inside video'
                                        )
                                    }}
                                >
                                    {Strings.watch_online}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="py-3">
                        <Card
                            body
                            onClick={() => {
                                window.open(
                                    `https://www.youtube.com/watch?v=N2GVRvYuGU4`
                                )
                            }}
                        >
                            <img
                                className={'sermon-thumbnail'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Making_Decisions.png"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Making Decisions
                                </CardTitle>

                                <CardText>{Strings.making_decisions}</CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent('Making Decisions video')
                                    }}
                                >
                                    {Strings.watch_online}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col xs="12" sm="6" md="4" className="py-3">
                        <Card
                            body
                            onClick={() => {
                                window.open(
                                    `https://www.youtube.com/playlist?list=PLrz-Es7TsDMM1EPAcx4VPtcGC3tose8gI`
                                )
                            }}
                        >
                            <img
                                className={'sermon-thumbnail'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Conviction_Condemnation.png"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Conviction vs Condemnation
                                </CardTitle>

                                <CardText>
                                    {Strings.conviction_condemnation}
                                </CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Conviction vs Condemnation video'
                                        )
                                    }}
                                >
                                    {Strings.watch_online}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="py-3">
                        <Card
                            body
                            onClick={() => {
                                window.open(
                                    `https://www.youtube.com/playlist?list=PLrz-Es7TsDMNicrMqPvNkJo3DQVfoEQnI`
                                )
                            }}
                        >
                            <img
                                className={'sermon-thumbnail'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Theocratic_Government.png"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Theocratic Government & Order
                                </CardTitle>

                                <CardText>
                                    {Strings.theocratic_government}
                                </CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Theocratic Government & Order video'
                                        )
                                    }}
                                >
                                    {Strings.watch_online}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="py-3">
                        <Card
                            body
                            onClick={() => {
                                window.open(
                                    `https://www.youtube.com/playlist?list=PLrz-Es7TsDMM96MRHzZTZ7R0yHSkfCIcC`
                                )
                            }}
                        >
                            <img
                                className={'sermon-thumbnail'}
                                alt="Sample"
                                src="https://d1biklpwskup2.cloudfront.net/Provoked_By_God.png"
                            />
                            <CardBody>
                                <CardTitle tag="h5" className={'minister-name'}>
                                    Provoked By God to Contend for Perfection
                                </CardTitle>

                                <CardText>
                                    {
                                        'There are three groups identified in the end times: those who are perfected, those who are protected, and those who are prematurely destroyed.'
                                    }
                                </CardText>
                                <Button
                                    onClick={() => {
                                        trackEvent(
                                            'Provoked By God to Content video'
                                        )
                                    }}
                                >
                                    {Strings.watch_online}
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </BasePage>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}

export default connect(mapStateToProps)(WatchOnlinePage)
