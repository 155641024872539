/** @format */

import { Component } from 'react'
import React from 'react'
import {
    Navbar,
    NavbarToggler,
    Collapse,
    Nav,
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Badge,
} from 'reactstrap'
import { Strings } from '../../localizations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Navbar.scss'
import { connect } from 'react-redux'
import { trackEvent } from '../../services/GoogleApiService'

export class HOENavbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
        }

        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        return (
            <Container fluid className="hoe-navbar">
                <Row className={'social-buttons'}>
                    <Col
                        xs={'4'}
                        sm={'6'}
                        md={'7'}
                        lg={'8'}
                        className="organization-name"
                    ></Col>
                    <Col xs={'8'} sm={'6'} md={'5'} lg={'4'}>
                        <a
                            href={
                                this.props.language === 'en'
                                    ? process.env.REACT_APP_INSTAGRAM_EN
                                    : process.env.REACT_APP_INSTAGRAM_ES
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                trackEvent(
                                    this.props.language === 'en'
                                        ? 'Goto English Instagram'
                                        : 'Goto Spanish Instagram'
                                )
                            }
                        >
                            <FontAwesomeIcon icon={['fab', 'instagram']} />
                        </a>
                        <a
                            href={
                                this.props.language === 'en'
                                    ? process.env.REACT_APP_FACEBOOK_EN
                                    : process.env.REACT_APP_FACEBOOK_ES
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                trackEvent(
                                    this.props.language === 'en'
                                        ? 'Goto English Facebook'
                                        : 'Goto Spanish Facebook'
                                )
                            }
                        >
                            <FontAwesomeIcon icon={['fab', 'facebook']} />
                        </a>

                        <a
                            href={
                                this.props.language === 'es'
                                    ? process.env.REACT_APP_YOUTUBE_ES
                                    : process.env.REACT_APP_YOUTUBE_EN
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                trackEvent(
                                    this.props.language === 'en'
                                        ? 'Goto English Youtube'
                                        : 'Goto Spanish Youtube'
                                )
                            }
                        >
                            <FontAwesomeIcon icon={['fab', 'youtube']} />
                        </a>

                        <a
                            href={`mailto:${process.env.REACT_APP_ORGANIZATION_EMAIL}`}
                            onClick={() => trackEvent('Send Email')}
                        >
                            <FontAwesomeIcon icon={['fa', 'envelope']} />
                        </a>
                    </Col>
                </Row>
                <Row className={'logo-row'}>
                    <Col sm={'2'}>
                        <NavItem>
                            <NavLink href="/">
                                <span
                                    className={`hoe-logo ${
                                        window.location.pathname === '/'
                                            ? ''
                                            : 'non-home'
                                    }`}
                                />
                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col sm={'2'}></Col>
                    <Col sm={'8'}>
                        <Navbar light expand="md">
                            <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav className="ml-auto" navbar>
                                    {/* <NavItem>
                                        <NavLink href="/donate">Donate</NavLink>
                                    </NavItem> */}
                                    <NavItem>
                                        <NavLink href="/contact-us">
                                            {Strings.contact_us}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="/calendar">
                                            {Strings.calendar}
                                        </NavLink>
                                    </NavItem>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {Strings.about_us}
                                        </DropdownToggle>
                                        <DropdownMenu end={true}>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/our-ministers">
                                                        {Strings.our_ministers}
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/what-we-believe">
                                                        What We Believe
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/newcomer">
                                                        {
                                                            Strings.getting_started
                                                        }
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>

                                            {/* <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/ephraim-symbol">
                                                        Ephraim's Symbol
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/ephraim-challenge">
                                                        The Ephraim Challenge
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem> */}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {Strings.resources}
                                        </DropdownToggle>
                                        <DropdownMenu end={true}>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/videos">
                                                        <Badge color={'danger'}>
                                                            Online Video Sermons
                                                        </Badge>
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink href="/newcomer">
                                                        {Strings.study_outlines}
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>

                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink
                                                        target={'_blank'}
                                                        href={
                                                            this.props
                                                                .language ===
                                                            'en'
                                                                ? process.env
                                                                      .REACT_APP_YOUTUBE_EN
                                                                : process.env
                                                                      .REACT_APP_YOUTUBE_ES
                                                        }
                                                    >
                                                        {Strings.watch_youtube}
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            </Collapse>
                            <UncontrolledDropdown group>
                                <img
                                    src={
                                        this.props.language === 'en'
                                            ? './assets/usa_icon.png'
                                            : './assets/dominican_republic_icon.png'
                                    }
                                    alt="..."
                                    width={40}
                                />
                                <DropdownToggle
                                    caret
                                    color="secondary"
                                    outline
                                    size="sm"
                                />
                                <DropdownMenu>
                                    <DropdownItem header>
                                        {Strings.language}
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.props.toggleEnglish}
                                    >
                                        <span>
                                            {Strings.english}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        <img
                                            src={'./assets/usa_icon.png'}
                                            alt="..."
                                            width={40}
                                        />
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.props.toggleSpanish}
                                    >
                                        <span>
                                            {Strings.spanish}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                        <img
                                            src={
                                                './assets/dominican_republic_icon.png'
                                            }
                                            alt="..."
                                            width={40}
                                        />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return { language: state.language }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        toggleEnglish: () => {
            localStorage.setItem('language', 'en')
            Strings.setLanguage('en')
            return dispatch({ type: 'UPDATE_LANG', payload: 'en' })
        },
        toggleSpanish: () => {
            localStorage.setItem('language', 'es')
            Strings.setLanguage('es')
            return dispatch({ type: 'UPDATE_LANG', payload: 'es' })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HOENavbar)
